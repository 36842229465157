import styled from '@emotion/styled';
import {
  Box,
  Card,
  Typography,
  Grid,
  Divider,
  Paper,
  Chip
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ASSIGNMENT_TYPES, CERTIFICATION_STEP_TYPES, ROLES } from 'src/constants';
import { CertificationStepStatus } from 'src/models/certification';
import {
  getCurrentCyclePeriodsMetadata,
  getCurrentCertificationStep,
  getIsStepGracePeriodOver,
  inProgressStatuses,
  notStartedStatuses,
  isBaseline,
  isStepCompleted,
  isStepInCurrentCycleOngoingYear,
  isNextYearPeriodActive
} from './certificationUtil';
import { getStatusConfig } from './certificationStatusConfig';
import useCertificationAssignmentType from './useCertificationAssignmentType';
import CertificationOverview from './CertificationOverview';
import CertificationProgressBar from './CertificationProgressBar';
import JourneyAccordion from './JourneyAccordion';
import { PeriodsConfig } from '../certification/periodsConfig';
import useStore from '../store';

const { PSA, PSM } = ASSIGNMENT_TYPES;

const StyledChip = styled(Chip)`
  font-size: 11px;
  color: #263238;
  background-color: #ECEFF1;
  text-transform: uppercase;
`;

const StyledYear = styled(Typography)`
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.tertiary.dark};
`;

const getCompletionPercentage = certificationStep => {
  if (!certificationStep?.completion) return 0;
  const { completion } = certificationStep;
  return (completion.completed / completion.total) * 100;
};

const isHistoricalStep = (step, statusConfig, currentCertificationCycle, currentDate, periods) => {
  const { periodMeta } = statusConfig;
  const isBaseline = step.type === CERTIFICATION_STEP_TYPES.BASELINE;
  const isApproved = step.status === CertificationStepStatus.APPROVED;
  const isCurrentStepGracePeriodOver = getIsStepGracePeriodOver(periods, currentDate, step);
  const isCurrentCycleStep = isStepInCurrentCycleOngoingYear(step, currentCertificationCycle, currentDate);
  if (isBaseline && !isApproved) return false;
  if (!isStepCompleted(step)) return false;
  if (isNextYearPeriodActive(periodMeta, currentDate)) return true;
  if (!isBaseline && isCurrentCycleStep && !isCurrentStepGracePeriodOver && !step.asset) return false;
  return true;
};

export default function CertificationJourney({
  certification,
  certificationSteps = [],
  certificationCycle,
  lastCertificationActivity
}) {
  if (!certification) return null;

  const { beginNextStep } = useStore(state => state.certification);
  const { user } = useStore(state => state.auth);
  const { assessmentRanges, currentDate } = useStore(state => state.settings);
  const history = useHistory();
  const isBaselineAssessment = isBaseline(certification);
  const previousCertificationStatus = lastCertificationActivity?.data?.previousStatus;
  const currentCertificationStep = getCurrentCertificationStep(certificationSteps);
  const certificationAssignmentType = useCertificationAssignmentType();
  const isPsTeamMember = [PSA, PSM].includes(certificationAssignmentType);
  const isAdmin = user?.role === ROLES.ADMIN;
  const completionPercentage = getCompletionPercentage(currentCertificationStep);
  const periodsConfig = new PeriodsConfig(assessmentRanges, currentDate);
  const periods = periodsConfig.getPeriods();
  const params = {
    certification,
    isBaseline: isBaselineAssessment,
    previousCertificationStatus,
    currentCertificationCycle: certificationCycle,
    currentCertificationStep,
    isPsTeamMember,
    isAdmin,
    beginNextStep,
    navigateCallback: history,
    periods,
    currentDate
  };
  const statusConfig = getStatusConfig(params);
  const { certificationStatus, assessmentType, periodMeta } = statusConfig;
  const inProgress = inProgressStatuses.includes(certificationStatus);
  const notStarted = notStartedStatuses.includes(certificationStatus);
  const periodsParams = {
    certificationId: certification.id,
    certificationStatus,
    certificationCycle,
    certificationSteps,
    isAdmin,
    isPsTeamMember,
    periodMeta,
    periods,
    currentDate
  };
  const periodsWithMetadata = getCurrentCyclePeriodsMetadata(periodsParams);
  const historicalSteps = certificationSteps.filter(step => {
    return isHistoricalStep(step, statusConfig, certificationCycle, currentDate, periods);
  });
  const currentYear = currentDate.getFullYear();

  return (
    <>
      <Card>
        <Paper square>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{ display: 'flex', alignItems: 'center', px: 2, minHeight: 52 }}
            >
              {isBaselineAssessment ? 'Baseline' : 'Certification'} Assessment
            </Typography>
            {inProgress && <CertificationProgressBar variant="determinate" value={completionPercentage} />}
            {notStarted && <StyledChip size='small' label="Not Started" />}
            <StyledYear sx={{ px: 2 }}>
              {currentYear}
            </StyledYear>
          </Box>
          <Divider sx={{ mx: 2 }} />
          <Grid mx={2} pt={1} pb={2}>
            <CertificationOverview
              statusConfig={statusConfig}
              periods={periodsWithMetadata}
              currentDate={currentDate} />
          </Grid>
        </Paper>
      </Card>
      <JourneyAccordion
        certification={certification}
        certificationSteps={historicalSteps}
        assessmentType={assessmentType}
        title="Historical Data"
        isAdmin={isAdmin}
        isPsTeamMember={isPsTeamMember}
        currentDate={currentDate}
      />
    </>
  );
}
